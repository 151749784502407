import React from 'react';
import SVGLogoMix from '../svg/SVGLogoMix';
import SVGLogoText from '../svg/SVGLogoCircle';
import { useSuperTheme } from '@soldui';
import { Box } from '@mui/material';


function CombinedLogoRow() {
  const magic = useSuperTheme().magic;
  return (
    <Box display="flex" sx = {{ 'height': magic.logoFix }}>
      <SVGLogoMix />
    </Box>
  );
}

function LogoSquare() {
  return (
    <Box display="flex" sx = {{ 'maxWidth': '33vw' }}>
      <SVGLogoText />
    </Box>
  );
}

export { CombinedLogoRow, LogoSquare };
