import Cookies from 'js-cookie';
import { type CookieAttributes } from 'js-cookie';

class SafeCookies {
  private version: string;
  private defaultAttributes: CookieAttributes;

  constructor(version: string, defaultAttributes: CookieAttributes = {}) {
    this.version = version;
    this.defaultAttributes = {
      secure: true, // Default to secure cookies
      sameSite: 'strict', // Default to strict sameSite policy
      ...defaultAttributes // Allow overriding default settings
    };
  }

  // Function to append the version to cookie names
  private getRawName(name: string): string {
    return `${name}_${this.version}`;
  }

  // Retrieves a cookie with the version appended to its name
  get(name: string) {
    return Cookies.get(this.getRawName(name));
  }

  // Sets a cookie with the version appended to its name, and merges given options with defaults
  set(name: string, value: string, options?: CookieAttributes) {
    const finalOptions: CookieAttributes = {
      ...this.defaultAttributes, // Start with default attributes
      ...options // Apply any user-specified options
    };
    Cookies.set(this.getRawName(name), value, finalOptions);
  }
}

// Example usage:
// Instantiate SafeCookies with version 'v1' and custom default attributes
const SoldwebCookies = new SafeCookies('v1', {
  expires: 365, // Default to cookies expiring in 365 days
});

export { SoldwebCookies };
