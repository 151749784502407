/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { Divider, Stack, Paper, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SoldwebBackground } from 'src/ui/site/Background';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const Items2 = [
  'Item 1',
  'Item 2',
  'Item 3',
  'Item 4',
  'Item 5',
  'Item 6',
  'Item 7',
];


const DetailedNavBar: React.FC<{}> = () => {
  return (
    // <Container
    //   maxWidth={false}
    //   disableGutters={true}
    //   
    // >
      <Stack
        direction="row"
        justifyContent="space-between"
        // justifyContent="space-evenly"
        alignItems="center"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        sx={{ backgroundColor: 'hsla(315, 80%, 40%, 0.5)',
          // width: '100%',
          ml: 'auto', mr: 'auto' }}
      >
        {
          Items2.map((value, index) => (<Item> {value} </Item>))
        }
      </Stack>
    // </Container>
  );
};
const Items = [
  (<Item>[Item 1]</Item>),
  (<DetailedNavBar/>),
  (<Item>[Item 7]</Item>),
];
const NavBar: React.FC<{}> = () => {
  return (
    // <Container
    //   maxWidth={false}
    //   disableGutters={true}
    //   sx={{ backgroundColor: 'hsla(215, 50%, 60%, 0.5)' }}
    // >
      <Stack
        direction="row"
        // justifyContent="space-between"
        justifyContent="space-evenly"
        alignItems="center"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
      >
        {
          Items.map((value, index) => (<>{value}</>))
        }
      </Stack>
    // </Container>
  );
};

export { NavBar };