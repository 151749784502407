import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
// import Logo1 from './Logo1';
// import Logo2 from './Logo2';
// import SVGLogoCircle from '../svg/SVGLogoCircle';
import { Stack } from '@mui/material';
import AppButton from './AppButton';
import { useSuperTheme } from 'src/ui/theme/SuperTheme';
import Divider from '@mui/material/Divider';

const pages = ['About', 'Novel', 'Demo'];

function MainBar({ links }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const magic = useSuperTheme().magic;

  const barStyle = { 'height': magic.barFix };

  return (
    <AppBar position="fixed" sx={barStyle}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ padding: '0px' }}>
          <AppButton isLogo to="/"></AppButton>
          <Box sx={{}}>
            {/* Case1: Wide Space */}
            <Box sx={{ flexGrow: 0, padding: 1, display: { xs: 'none', md: 'flex' } }}>
              {
                links.map((link) => (<AppButton key={link.path} to={link.path}>{link.name}</AppButton>))
              }
            </Box>
          </Box>
          {/* Case2: No Space */}
          <Box sx={{ flexGrow: 1, justifyContent: 'right', display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'flex', md: 'none' },
              }}
            >
              {
                links.map((link) => (<AppButton key={link.path} to={link.path}>{link.name}</AppButton>))
              }
            </Menu>
          </Box>

        </Toolbar>
      </Container>
    </AppBar>
  );
}
export { MainBar };
