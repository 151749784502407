// import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { CombinedLogoRow } from '../site/Logo';
import ButtonBase from '@mui/material/ButtonBase';

function AppButton({ to, isLogo = false, children, ...rest }) {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = location.pathname === to;  // ugly but work

  const handleClick = () => {
    navigate(to);
  };

  if (isLogo) {
    return (
      <ButtonBase
        onClick={handleClick}
        sx={{ margin: 1, textTransform: 'none' }}
        {...rest}
      >
        <CombinedLogoRow />
      </ButtonBase>
    );
  }

  return (
    <Button
      variant={"text"}
      size="large"
      onClick={handleClick}
      sx={{ margin: 1, textTransform: 'none', color: isActive ? "rgba(11, 15, 133, 0.9)" : "black" }}
      {...rest}
    >
      {children}
    </Button>
  );
}

export default AppButton;