/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { Button, Container, Typography, Box, Stack, Grid, Avatar, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SoldwebBackground, getSoldWebTheme, MainBar, useSuperTheme } from '@soldui';

import { SxProps, Theme } from '@mui/system';

interface RegularProps {
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
}
interface CommonProps {
  links: Array<Object>;
};

const SoldwebFooter: React.FC = () => {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        // justifyContent="space-evenly"
        alignItems="center"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        sx={{
          // backgroundColor: 'hsla(315, 80%, 40%, 0.5)',
          // width: '100%',
          ml: 'auto',
          mr: 'auto'
        }}>
        <Typography>
          Copyright © 2024 SOLD API. All rights reserved.
        </Typography>
        {/* <Typography>
          nphard001@gmail.com
        </Typography> */}
      </Stack>
    </>
  );
};

const SoldwebHeader: React.FC<CommonProps> = ({ links }) => {
  return (
    <>
      <MainBar links={links} />
      <SoldwebBackground />
    </>
  );
};


const RegularContainer: React.FC<RegularProps> = ({ children, sx, ...otherProps }) => {
  return (
    <Container
      maxWidth="lg"
      disableGutters={false}
      sx={{ pt: 0, ...sx }}
      {...otherProps}
    >
      {children}
    </Container>
  );
};

const RegularPage: React.FC<RegularProps> = ({ children, sx, ...otherProps }) => {
  return (
    <Container
      maxWidth="lg"
      disableGutters={false}
      sx={{ my: 3, pt: 0, ...sx }}
      {...otherProps}
    >
      {children}
    </Container>
  );
};


export { SoldwebHeader, SoldwebFooter, RegularContainer, RegularPage };