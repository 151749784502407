import React from 'react';
function SVGLogoText() {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 302"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,302.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
        <path d="M950 3003 c-138 -14 -345 -74 -461 -134 -157 -81 -258 -190 -349
-374 -167 -341 -122 -625 144 -903 163 -170 237 -211 611 -334 371 -123 494
-187 539 -282 20 -43 21 -135 1 -184 -54 -128 -229 -203 -440 -189 -142 10
-231 38 -448 143 -145 71 -204 95 -221 90 -48 -12 -252 -197 -295 -267 -39
-64 -19 -156 55 -246 49 -60 188 -149 303 -194 405 -159 884 -169 1189 -24
248 118 430 326 503 577 29 101 37 323 15 428 -25 120 -82 222 -182 328 -145
155 -310 247 -616 343 -243 76 -428 154 -527 222 -78 53 -100 141 -57 232 59
128 215 209 401 208 104 0 150 -15 331 -106 278 -139 326 -123 538 180 40 58
56 89 51 102 -10 32 -158 166 -231 209 -222 132 -574 204 -854 175z"/>
        <path d="M4495 3004 c-232 -34 -372 -75 -540 -159 -360 -181 -659 -517 -780
-879 -31 -92 -45 -241 -45 -471 0 -232 14 -379 45 -472 118 -352 424 -688 778
-857 254 -121 545 -176 823 -155 138 10 158 13 288 46 177 43 334 112 482 211
338 226 578 593 651 995 23 126 22 352 -1 477 -107 561 -490 1012 -1008 1184
-176 59 -281 77 -483 81 -99 2 -193 1 -210 -1z m430 -653 c322 -85 550 -344
610 -696 49 -287 -52 -595 -258 -781 -172 -156 -426 -256 -652 -257 -131 0
-201 16 -330 78 -257 123 -422 318 -492 583 -22 83 -26 118 -26 237 0 123 3
151 27 230 48 159 132 292 262 415 181 171 331 223 624 216 117 -3 175 -9 235
-25z"/>
        <path d="M7455 2984 c-72 -18 -126 -41 -177 -75 l-58 -40 1 -947 c0 -1031 10
-1626 29 -1710 15 -65 58 -118 118 -145 35 -15 82 -21 225 -27 236 -12 764
-12 1037 0 358 15 456 46 496 157 16 45 19 281 4 332 -22 77 -86 112 -240 131
-70 8 -748 30 -952 30 l-58 0 0 843 c-1 853 -10 1252 -30 1319 -14 48 -67 93
-132 114 -52 16 -224 28 -263 18z"/>
        <path d="M10260 2979 c-135 -13 -205 -34 -261 -75 l-49 -36 0 -1357 0 -1358
56 -37 c68 -45 136 -61 329 -75 371 -28 840 1 1063 65 437 126 771 391 967
767 107 204 130 294 141 548 9 233 -8 436 -46 550 -69 207 -192 403 -354 564
-248 246 -541 378 -959 431 -147 18 -744 27 -887 13z m565 -644 c287 -8 429
-21 503 -44 145 -45 342 -220 424 -376 105 -203 122 -447 47 -680 -40 -124
-90 -205 -189 -306 -93 -94 -182 -153 -306 -201 -143 -55 -228 -69 -466 -75
l-217 -6 -5 24 c-8 37 -26 312 -36 554 -5 121 -10 424 -10 673 0 427 1 453 18
448 9 -2 116 -7 237 -11z"/>
        <path d="M14610 2974 c-129 -41 -176 -102 -324 -429 -57 -126 -253 -549 -436
-940 -476 -1018 -630 -1364 -630 -1419 0 -15 7 -41 16 -57 43 -84 304 -125
466 -73 101 33 186 142 284 364 l48 110 647 0 648 0 75 -148 c164 -324 192
-346 426 -346 149 0 220 17 265 64 28 30 30 36 29 108 -1 100 -23 156 -199
522 -321 666 -945 2046 -945 2088 0 20 -103 102 -172 137 -78 39 -123 43 -198
19z m219 -1254 c118 -237 256 -540 263 -575 3 -18 -6 -21 -72 -28 -82 -9 -678
-2 -722 9 -16 3 -28 12 -28 19 0 31 323 708 384 804 27 43 29 44 43 25 8 -10
67 -125 132 -254z"/>
        <path d="M20018 2977 c-58 -17 -118 -57 -140 -95 -39 -66 -41 -107 -45 -1439
l-4 -1293 61 -39 c92 -60 148 -75 265 -75 165 2 267 55 294 156 25 95 33 489
28 1493 -4 1020 -8 1120 -42 1185 -20 37 -65 77 -110 96 -53 23 -241 29 -307
11z"/>
        <path d="M17132 2970 c-64 -10 -142 -55 -160 -94 -20 -41 -39 -1968 -23 -2356
12 -318 25 -392 75 -444 39 -42 97 -52 266 -43 131 7 187 24 224 68 43 50 49
116 47 475 l-2 330 183 13 c393 27 593 68 771 157 215 109 382 300 457 523 27
82 50 235 50 341 0 431 -224 783 -593 930 -130 52 -300 78 -642 100 -182 11
-574 11 -653 0z m937 -615 c175 -43 300 -173 331 -343 30 -165 -74 -355 -236
-429 -60 -27 -230 -65 -374 -82 -99 -13 -155 -4 -182 26 -38 45 -42 79 -46
472 l-4 384 224 -6 c151 -4 244 -11 287 -22z"/>
      </g>
    </svg>

  );
}

export default SVGLogoText;