/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from "remark-breaks";
import remarkGfm from 'remark-gfm';
import axios from 'axios';
import { Paper } from "@mui/material";
import { styled } from '@mui/material/styles';

import rehypeHighlight from 'rehype-highlight';
import 'highlight.js/styles/atom-one-dark.css'; // 選擇高亮主題

const DemoPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: 'left',
  square: false,
  opacity: 0.85,
}));



interface MarkdownFromURLProps {
  url: string;
}

// Component for fetching and displaying Markdown from a given URL
const MarkdownFromURL: React.FC<MarkdownFromURLProps> = ({ url }) => {
  const [content, setContent] = useState<string>('Loading...');
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    var real_url = url;
    const isLocalhost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
    if (isLocalhost) {
      real_url = url.replace(/^https:\/\/[^/]+/, "http://127.0.0.1:3361");
    }

    // Fetch Markdown content from URL
    axios.get(real_url)
      .then(response => {
        // Replace single line breaks with double spaces and newline for proper markdown rendering
        // const processedContent = response.data.replace(/(?<!\n)\n(?!\n)/g, '  \n');
        const processedContent = response.data;
        setContent(processedContent);
      })
      .catch(() => {
        // Set content to "Failed." on error
        setContent("Failed.");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url]);

  return (
    <>
      <ReactMarkdown
        remarkPlugins={[remarkGfm, remarkBreaks as typeof remarkGfm]}
        // remarkPlugins={[remarkGfm]}
        // remarkRehypeOptions={}
        // rehypePlugins={[rehypeHighlight]}
      >
        {content}
      </ReactMarkdown>
    </>
  );
};


export { DemoPaper, MarkdownFromURL };