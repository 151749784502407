import { Theme } from '@mui/system';
import { createTheme, ThemeOptions  } from '@mui/material/styles';
import { useTheme as useMuiTheme } from '@mui/material/styles';

interface SuperTheme extends Theme {
  magic: {
    logoMin: string;
    logoFix: string;
    barFix: string;
  };
}

function createSuperTheme(options: ThemeOptions, magic: SuperTheme['magic']): SuperTheme {
  const theme = createTheme(options);
  return {
    ...theme,
    magic
  } as SuperTheme;
}

const useSuperTheme = (): SuperTheme => {
  return useMuiTheme() as SuperTheme;
};

export { createSuperTheme, useSuperTheme, type SuperTheme };
