/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState, useEffect, ReactNode } from 'react';
import { Button, Container, Typography, Box, Stack, Grid, Avatar, Divider, Paper } from '@mui/material';
import { CircularProgress, Card, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LogoSquare, RegularContainer, RegularPage } from '@soldui';
import { DemoPaper, MarkdownFromURL } from '@soldui';

const BasePaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: 'left',
  square: false,
  opacity: 0.9,
  elevation: 12,
}));
const WidePaper = styled(BasePaper)(({ theme }) => ({
  width: '100%',
  fontSize: '1.15rem',
}));
const MidPaper = styled(BasePaper)(({ theme }) => ({
  width: 'auto',
  minWidth: '10vw',
}));
const CardPaper = styled(BasePaper)(({ theme }) => ({
  width: 'auto',
  minWidth: '5vw',
  textAlign: 'center',
}));

const ThePage: React.FC<{}> = () => {
  const content1 = `
  # 吳海韜
  > 軟體工程師；經濟學愛好者
  `;
  // const markdownUrl = "https://soldapi.com/data/marks/showoff.md";
  const markdownUrl = "https://soldapi.com/human/about1.md";
  // const markdownUrl = "https://soldapi.com/indev/edge.md";
  // const markdownUrl = "http://127.0.0.1:3361/human/demo.md";
  return (
    <>
      <RegularPage>
        <WidePaper>
          <MarkdownFromURL url={markdownUrl} />
        </WidePaper>
      </RegularPage>
    </>
  );
};

export { ThePage };