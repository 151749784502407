/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState, useEffect, ReactNode } from 'react';
import { Button, Container, Typography, Box, Stack, Grid, Avatar, Divider, Paper } from '@mui/material';
import { CircularProgress, Card, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LogoSquare, RegularContainer, RegularPage } from '@soldui';
import { DemoPaper, MarkdownFromURL } from '@soldui';
import { useLocation } from "react-router-dom";


const BasePaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: 'left',
  square: false,
  opacity: 0.9,
  elevation: 12,
}));
const WidePaper = styled(BasePaper)(({ theme }) => ({
  width: '100%',
  fontSize: '1.15rem',
}));
const MidPaper = styled(BasePaper)(({ theme }) => ({
  width: 'auto',
  minWidth: '10vw',
}));
const CardPaper = styled(BasePaper)(({ theme }) => ({
  width: 'auto',
  minWidth: '5vw',
  textAlign: 'center',
}));


function Locater() {
  const location = useLocation(); // 獲取完整路徑
  const pathSegments = location.pathname.split('/').filter(Boolean); // 分割路徑並過濾掉空值

  return (
    <div>
      <h1>About</h1>
      <p>Full Path: {location.pathname}</p>
      <ul>
        {pathSegments.map((segment, index) => (
          <li key={index}>
            Segment {index + 1}: {segment}
          </li>
        ))}
      </ul>
      {/* 根據每一層的 segment 做出不同的渲染 */}
    </div>
  );
}

const ThePage: React.FC<{}> = () => {

  // let markdownUrl = "https://soldapi.com/data/marks/showoff.md";
  let markdownUrl = "https://soldapi.com/human/s2.md";
  // let markdownUrl = "https://soldapi.com/indev/edge.md";
  // let markdownUrl = "http://127.0.0.1:3361/human/demo.md";

  
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);
  markdownUrl = pathSegments[1] ? `https://soldapi.com/human/${pathSegments[1]}.md` : markdownUrl;
  

  return (
    <>
      <RegularPage>
        {/* <Locater></Locater> */}
        <WidePaper>
          <MarkdownFromURL url={markdownUrl} />
        </WidePaper>
      </RegularPage>
    </>
  );
};

export { ThePage };