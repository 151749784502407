import React from 'react';
function SVGLogoHead() {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2047 1986"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,1986.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
        <path d="M11425 19839 c-422 -23 -743 -74 -1190 -189 -1058 -272 -1970 -752
-2872 -1513 -325 -274 -626 -601 -950 -1032 -278 -370 -470 -683 -616 -1000
-288 -628 -489 -1372 -573 -2120 -12 -107 -19 -277 -23 -594 l-6 -444 -430
-157 c-707 -257 -1036 -396 -1560 -655 -602 -298 -1090 -590 -1560 -933 -709
-518 -1369 -1192 -1561 -1597 -88 -185 -96 -326 -28 -464 31 -63 60 -99 167
-208 l130 -133 51 0 c28 0 224 11 436 25 740 47 853 50 1730 50 845 0 1106 -7
1540 -41 247 -19 357 -34 564 -79 949 -204 1806 -560 2292 -951 139 -112 164
-160 164 -315 0 -329 -199 -900 -735 -2104 -488 -1098 -779 -1845 -885 -2275
-27 -111 -70 -411 -70 -496 0 -28 43 -73 388 -415 363 -360 532 -517 602 -562
l30 -19 11 29 c32 85 61 240 119 658 91 645 232 1150 545 1940 126 320 153
384 425 1020 393 918 529 1278 620 1630 22 87 42 161 44 163 2 2 66 -28 142
-66 216 -108 583 -248 974 -372 641 -203 799 -224 1735 -225 829 0 991 16
1480 147 843 225 1650 628 2441 1218 398 297 695 552 1169 1005 233 222 333
311 340 303 24 -24 -451 -838 -748 -1282 -187 -281 -738 -1023 -1377 -1856
-1482 -1932 -1948 -2649 -2373 -3650 -291 -687 -606 -1752 -642 -2177 l-7 -81
39 -12 c57 -17 648 -7 858 14 449 46 735 91 1025 162 1258 306 2382 869 3395
1699 494 405 1035 952 1441 1460 609 761 1137 1686 1544 2705 444 1112 691
2202 782 3445 17 238 17 1177 0 1410 -76 1020 -235 1869 -528 2820 -354 1147
-916 2223 -1622 3104 -371 463 -834 933 -1277 1296 -952 781 -2015 1297 -3235
1569 -396 88 -667 125 -1075 146 -222 12 -1062 11 -1280 -1z m-1015 -4089
c320 -22 638 -67 855 -121 447 -112 1017 -364 1405 -622 452 -300 1143 -1023
1412 -1477 28 -47 88 -159 133 -250 285 -570 447 -1153 496 -1790 14 -190 7
-572 -15 -755 -118 -971 -551 -1737 -1301 -2301 -823 -620 -1962 -908 -3071
-779 -837 98 -1576 393 -2809 1122 -311 184 -540 313 -813 460 -198 106 -247
130 -527 260 -88 41 -163 77 -168 82 -4 4 93 20 215 35 682 85 1387 208 2088
362 445 98 760 187 760 215 0 16 -115 63 -225 93 -399 108 -543 160 -800 286
-698 342 -1190 878 -1345 1465 -55 210 -65 306 -65 675 0 356 6 433 52 639 58
264 211 615 378 866 511 768 1362 1315 2335 1500 74 14 176 29 225 34 129 12
614 13 785 1z m-5050 -4010 c30 -16 112 -135 240 -350 143 -238 311 -497 429
-659 45 -62 80 -115 78 -117 -8 -9 -540 -127 -757 -168 -621 -119 -1851 -321
-2620 -430 -475 -68 -653 -87 -765 -84 -120 4 -128 14 -63 84 174 189 1260
868 1723 1077 533 241 1603 656 1693 657 13 0 32 -4 42 -10z"/>
        <path d="M9745 12805 c-307 -67 -552 -294 -637 -592 -30 -102 -32 -303 -5
-403 42 -153 164 -338 290 -441 146 -118 290 -164 517 -164 159 1 238 17 365
74 211 96 375 291 435 517 21 82 29 240 15 319 -58 332 -307 603 -629 685 -93
24 -257 26 -351 5z"/>
      </g>
    </svg>

  );
}

export default SVGLogoHead;