import { createStore, useStore } from 'zustand';
import { SoldwebCookies } from '@soldjs';

// type Theme = 'light' | 'dark' | 'colorful';
type Theme = 'light' | 'dark';
type Language = 'en' | 'zh';

interface UIState {
  theme: Theme;
  language: Language;
  setTheme: (theme: Theme) => void;
  setLanguage: (language: Language) => void;
}

const defaultTheme: Theme = 'light';
const defaultLanguage: Language = 'zh';

const createdUIStore = createStore<UIState>((set) => ({
  theme: (SoldwebCookies.get('theme') as Theme) || defaultTheme,
  language: (SoldwebCookies.get('language') as Language) || defaultLanguage,
  setTheme: (theme: Theme) => {
    SoldwebCookies.set('theme', theme, { expires: 365 });
    set({ theme });
  },
  setLanguage: (language: Language) => {
    SoldwebCookies.set('language', language, { expires: 365 });
    set({ language });
  },
}));

const useUIStore = (selector: (state: UIState) => any) => useStore(createdUIStore, selector);

export { useUIStore };