import { createSuperTheme, SuperTheme } from "./SuperTheme";

// source: https://colorhunt.co/palette/f9f7f7dbe2ef3f72af112d4e
const paletteNavy = {
  white: 'hsl(0, 14%, 97%)',
  light: 'hsl(219, 38%, 89%)',
  dark: 'hsl(212, 47%, 46%)',
  black: 'hsl(212, 64%, 18%)',
}

// source: https://colorhunt.co/palette/be9fe1c9b6e4e1ccecf1f1f6
const palettePurple = {
  white: 'hsl(240, 21%, 95%)',
  light: 'hsl(279, 45%, 86%)',
  dark: 'hsl(264, 46%, 80%)',
  black: 'hsl(268, 52%, 75%)',
}

const PL = paletteNavy;
const PL2 = palettePurple;

const getSoldWebTheme = (mode: 'light' | 'dark'): SuperTheme => createSuperTheme({
    palette: {
      mode: mode,
      primary: {
        main: PL.dark,
        light: PL.light,
        contrastText: PL.white,
        dark: PL.black,
      },
      secondary: {
        main: PL2.dark,
        light: PL2.light,
        contrastText: PL2.white,
        dark: PL2.black,
      },
    },
    typography: {
      fontFamily: 'Noto Sans TC'
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            color: "black",
            backgroundColor: "white",
            backdropFilter: "saturate(180%) blur(5px)",
            background: "hsla(0, 0%, 100%, .85)",
          }
        }
      }
    }
  }, {
    logoMin: '4px',
    logoFix: '50px',
    barFix: '64px',
  });

export { getSoldWebTheme };