/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useUIStore } from '@soldui';
import { Container, Paper, ThemeProvider, Typography, Stack, Box } from '@mui/material';
// import Demo from 'src/demo/mdload';
import Demo2 from 'src/demo/mdshow';
import Demo3 from 'src/demo/viewTheme';
// import Demo6 from 'src/demo/momd';
import Demo6 from 'src/demo/secret';
import Demo4 from 'src/demo/vertLay';
import Demo5 from 'src/demo/chat';
import { IndevPage as Indev1 } from 'src/indev/soldAPPBar';
import { SoldwebFooter, SoldwebHeader, getSoldWebTheme, useSuperTheme } from '@soldui';
import { ThePage as Home } from 'src/page/Home';
import { ThePage as About } from 'src/page/About';
import { ThePage as Proj } from 'src/page/Proj';
import { ThePage as Secret } from 'src/page/Secret';


let menuLinks = [
  { name: 'Home', path: '/', tooltip: 'Back to Home', component: Home },
  { name: 'About', path: '/about', tooltip: 'Learn more about us', component: About },
  { name: 'Projects', path: '/projects', tooltip: 'See our projects', component: Proj },
];

if (false) {
  menuLinks = [
    ...menuLinks,
    // { name: 'APPBar', path: '/indev1', tooltip: 'Indev', component: Indev1 },
    // { name: 'Chat', path: '/demo5', tooltip: 'Demos (dev-only)', component: Demo5 },
    // { name: 'mdload', path: '/demo1', tooltip: 'Demos (dev-only)', component: Demo },
    // { name: 'mond', path: '/demo6', tooltip: 'Demos (dev-only)', component: Demo6 },
  
    { name: 'secret', path: '/demo6', tooltip: 'Demos (dev-only)', component: Demo6 },
    { name: 'MUITheme', path: '/demo3', tooltip: 'Demos (dev-only)', component: Demo3 },
    // { name: 'vertLay', path: '/demo4', tooltip: 'Demos (dev-only)', component: Demo4 },
  ];
}

let navigationLinks = [
  ...menuLinks,
  { name: 'Secret', path: '/gogo5566*', tooltip: 'See our projects', component: Secret },
];

function App() {
  const { theme, setTheme } = useUIStore(state => ({
    theme: state.theme,
    setTheme: state.setTheme
  }));
  const muiTheme = useMemo(() => getSoldWebTheme(theme), [theme]);

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  const magic = muiTheme.magic;
  const debugColor = false;
  // const debugColor = true;

  return (
    <ThemeProvider theme={muiTheme}>
      <Router>
        <Stack
          direction="column"
          spacing={0}
          sx={{
            padding: 0,
            margin: '0 auto',
            width: '100%',
            minHeight: '100vh',
            boxSizing: 'border-box'
          }}
        >
          {/* -------- Part1 -------- */}
          <Box
            component={'header'}
            sx={{
              flexGrow: 0,
              display: 'flex',
              flexDirection: 'column',
              height: '64px', // FIXME, should come with header bar
              backgroundColor: debugColor ? 'hsla(120, 80%, 40%, 0.2)' : undefined,
            }}>
            <SoldwebHeader links={menuLinks} />
          </Box>
          {/* -------- Part2 -------- */}
          <Box
            component={'main'}
            sx={{
              flexGrow: 0,
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: debugColor ? 'hsla(211, 80%, 40%, 0.2)' : undefined,
            }}>
              {/* <button onClick={toggleTheme}>Toggle Theme</button> */}
              <Routes>
                {navigationLinks.map((link) => (
                  <Route key={link.path} path={link.path} element={React.createElement(link.component)} />
                ))}
              </Routes>
          </Box>
          {/* -------- Part3 (absorb) -------- */}
          <Box
            component={'span'}
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: debugColor ? 'hsla(315, 80%, 40%, 0.2)' : undefined,
            }}>

          </Box>
          {/* -------- Part4 -------- */}
          <Box
            component="footer"
            sx={{
              flexGrow: 0,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <SoldwebFooter />
          </Box>
        </Stack>
      </Router>
    </ThemeProvider>
  );
}

export default App;
