import React from 'react';
import { styled } from '@mui/material/styles';

const FixedFullPage = styled('div')(({ theme }) => ({
  position: 'fixed',
  overflow: 'hidden',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100vw',
  height: '100vh'
}));
const BackgroundImage = styled(FixedFullPage)(({ theme }) => ({
  zIndex: -10,
  backgroundImage: 'url("https://soldapi.com/data/bg968v4.png")',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center'
}));

const BackgroundShim1 = styled(FixedFullPage)(({ theme }) => ({
  zIndex: -9,
  backgroundColor: 'hsla(0, 0%, 98%, 0.8)',
  backdropFilter: 'saturate(60%) blur(2px)',
}));
const BackgroundShim2 = styled(FixedFullPage)(({ theme }) => ({
  zIndex: -9,
  backgroundColor: 'hsla(215, 20%, 95%, 0.6)',
  backdropFilter: 'saturate(60%) blur(2px)',
}));

const SoldwebBackground: React.FC<{}> = () => {
  return (
    <>
      <BackgroundImage />
      <BackgroundShim2 />
    </>
  );
};

export { SoldwebBackground };