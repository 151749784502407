/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import SVGLogoCircle from './SVGLogoCircle';
import SVGLogoHead from './SVGLogoHead';
import SVGLogoText from './SVGLogoText';

function SVGLogoMix({ tw = 3, th = 0.4, lw = 0.7 }) {
  const textWeighting = tw;
  const textHeightRatio = th;
  const totalWeight = 1 + textWeighting;
  const textYPosition = (1 - textHeightRatio) / 2;
  const logoWeight = lw;
  const logoPosition = (1 - logoWeight) / 2;

  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${totalWeight} 1`} preserveAspectRatio="xMidYMid meet">
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg" x={logoPosition} y={logoPosition} width={logoWeight} height={logoWeight} preserveAspectRatio="xMidYMid meet">
        <SVGLogoHead />
      </svg>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg" x="1" y={textYPosition} width={textWeighting} height={textHeightRatio} preserveAspectRatio="xMidYMid meet">
        <SVGLogoText />
      </svg>
    </svg>
  );
}

export default SVGLogoMix;
