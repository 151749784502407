/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState, useEffect } from 'react';
import { Button, Container, Typography, Box, Stack, Grid, Avatar, Divider, Paper, ImageList, ImageListItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { LogoSquare, RegularContainer, RegularPage, MarkdownFromURL } from '@soldui';

const BasePaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: 'left',
  square: false,
  opacity: 0.9,
  elevation: 12,
}));
const WidePaper = styled(BasePaper)(({ theme }) => ({
  width: '100%',
  fontSize: '1.1rem',
}));
const MidPaper = styled(BasePaper)(({ theme }) => ({
  width: 'auto',
  fontSize: '1.1rem',
}));
const TinyPaper = styled(BasePaper)(({ theme }) => ({
  width: 'auto',
  fontSize: '0.9rem',
  // maxWidth: '25vw',
}));
const CardPaper = styled(BasePaper)(({ theme }) => ({
  width: 'auto',
  minWidth: '5vw',
  textAlign: 'center',
  fontSize: '0.85rem',
  opacity: 0.5,
}));
const BackgroundImage = styled("img")({
  position: "absolute",
  right: 0,
  top: 0,
  width: "auto", // 設定圖片寬度
  height: "100%", // 設定圖片高度 (正方形)
  opacity: 0.35, // 半透明效果
  pointerEvents: "none", // 確保圖片不干擾互動
  zIndex: -1, // 保持圖片在 <Paper> 的下層
});

const ThePage: React.FC<{}> = () => {
  const content1 = `
  # 吳海韜
  > 軟體工程師；經濟學愛好者
  `;
  const contents = [
    `# AI`,
    `# C/C++`,
    `# Python`,
    `# ChatGPT`,
    `# Stable Diffusion`,
    `# React`,
    `# AWS`,
    `# PyTorch`,
    `# Financial Engineering`,
    `# Quantitative Finance`,
    `# Deep Learning`,
    `# Econometrics`,
  ];
  const samples = Array.from({ length: 12 }, (_, k) => contents[k % contents.length]);
  const markdownUrl2 = "https://soldapi.com/human/home2.md";
  const markdownUrl3 = "https://soldapi.com/human/home3.md";
  const markdownUrl4 = "https://soldapi.com/human/home4.md";

  return (
    <>
      <RegularPage>
        <Box sx={{ my: 2 }}>

          <Stack
            direction="row"
            useFlexGap flexWrap="wrap"
            justifyContent="space-around"
            alignItems="center"
            spacing={1}
          >

            <WidePaper sx={{position: "relative"}}>
              <MarkdownFromURL url={markdownUrl2} />
              <BackgroundImage src={'https://soldapi.com/data/t1.png'} alt="sd1" />
            </WidePaper>

            
          </Stack>
        </Box>
        {/* <Box sx={{ my: 2 }}>

          <Stack
            direction="row"
            useFlexGap flexWrap="wrap"
            justifyContent="space-around"
            alignItems="center"
            spacing={1}
          >

            <TinyPaper>
              <MarkdownFromURL url={markdownUrl3} />
            </TinyPaper>

            <TinyPaper>
              <MarkdownFromURL url={markdownUrl4} />
            </TinyPaper>
            
          </Stack>
        </Box> */}
        <Stack
          direction="row"
          useFlexGap flexWrap="wrap"
          justifyContent="space-around"
          alignItems="center"
          spacing={1}
        >
          {samples.map((text, idx) => (
            <CardPaper key={idx} elevation={12}>
              <ReactMarkdown remarkPlugins={[remarkGfm]}>{text}</ReactMarkdown>
            </CardPaper>)
          )}
        </Stack>
      </RegularPage>
    </>
  );
};

export { ThePage };