import React from 'react';
import { useTheme, Paper, createTheme, ThemeProvider, Button, Card, CardContent, Typography, Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// 創建自定義主題
// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#556cd6',
//     },
//     secondary: {
//       main: '#19857b',
//     },
//     error: {
//       main: '#ff5252',
//     },
//     warning: {
//       main: '#ffb74d',
//     },
//     info: {
//       main: '#2196f3',
//     },
//     success: {
//       main: '#4caf50',
//     },
//   },
// });

function App() {
    const theme = useTheme();
  return (
    
      <Box sx={{ p: 2 }}>

      <Box sx={{ p: 2 }}>
        <Typography variant="h4" gutterBottom>
          MUI Theme Color Demonstration
        </Typography>
        <Card sx={{ marginBottom: 2 }}>
          <CardContent>
            <Typography variant="h5" color="primary.main" gutterBottom>
              Primary Color Text
            </Typography>
            <Typography variant="body1">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.
            </Typography>
          </CardContent>
        </Card>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography color="secondary.main">Secondary Color Accordion</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Cras mattis consectetur purus sit amet fermentum. Curabitur blandit tempus porttitor. Nulla vitae elit libero, a pharetra augue.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Card variant="outlined" sx={{ marginBottom: 2, borderColor: 'warning.main' }}>
          <CardContent>
            <Typography variant="h5" color="warning.main" gutterBottom>
              Warning Color Text
            </Typography>
            <Typography variant="body2">
              Maecenas sed diam eget risus varius blandit sit amet non magna. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh.
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ backgroundColor: 'info.main', color: '#fff', marginBottom: 2 }}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Info Color Card
            </Typography>
            <Typography variant="body2">
              Donec ullamcorper nulla non metus auctor fringilla. Vestibulum id ligula porta felis euismod semper.
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ backgroundColor: 'success.main', color: '#fff' }}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Success Color Card
            </Typography>
            <Typography variant="body2">
              Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.
            </Typography>
          </CardContent>
        </Card>
      </Box>
        <Typography variant="h4" gutterBottom>
          MUI Theme Color Demonstration
        </Typography>
        <Paper elevation={3} sx={{ p: 2, marginBottom: 2 }}>
          <Typography variant="h6" color="primary.main">
            Primary Color
          </Typography>
          <Button variant="contained" color="primary">
            Primary Button
          </Button>
        </Paper>
        <Paper elevation={3} sx={{ p: 2, marginBottom: 2 }}>
          <Typography variant="h6" color="secondary.main">
            Secondary Color
          </Typography>
          <Button variant="contained" color="secondary">
            Secondary Button
          </Button>
        </Paper>
        <Paper elevation={3} sx={{ p: 2, marginBottom: 2 }}>
          <Typography variant="h6" color="error.main">
            Error Color
          </Typography>
          <Button variant="contained" color="error">
            Error Button
          </Button>
        </Paper>
        <Paper elevation={3} sx={{ p: 2, marginBottom: 2 }}>
          <Typography variant="h6" color="warning.main">
            Warning Color
          </Typography>
          <Button variant="contained" color="warning">
            Warning Button
          </Button>
        </Paper>
        <Paper elevation={3} sx={{ p: 2, marginBottom: 2 }}>
          <Typography variant="h6" color="info.main">
            Info Color
          </Typography>
          <Button variant="contained" color="info">
            Info Button
          </Button>
        </Paper>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Typography variant="h6" color="success.main">
            Success Color
          </Typography>
          <Button variant="contained" color="success">
            Success Button
          </Button>
        </Paper>
      </Box>
  );
}

export default App;
